<template>
  <div class="page">
    <Navbar title="订单复审" :callback="back" type="SMP" />
    <div class="provider">
      <div class="title">提供商列表</div>
      <van-radio-group
        v-model="calChose"
        direction="horizontal"
        @change="changeProvider"
        :checked-color="COLOR_M"
      >
        <van-row
          class="item"
          v-for="(cal, index) in calculateList"
          :key="cal.providerCode"
        >
          <van-col span="3"
            ><div class="raido">
              <van-radio :name="index"></van-radio></div
          ></van-col>
          <van-col span="17">
            <van-row class="name">
              <van-col span="24"
                >提供商：{{ cal.providerName
                }}<van-tag
                  size="mini"
                  :color="COLOR_M"
                  v-if="cal.providerType === 'PSN'"
                  >个人</van-tag
                ><van-tag
                  size="mini"
                  :color="COLOR_S1"
                  v-if="cal.providerType === 'ETP'"
                  >企业</van-tag
                ></van-col
              >
            </van-row>
            <van-row>
              <van-col span="12" class="price color-s1"
                >基础价：{{ cal.priceBase }}元</van-col
              >
              <van-col span="12" class="price color-s1"
                >销售价：{{ cal.priceSale }}元</van-col
              >
            </van-row>
            <van-row>
              <van-col span="24" class="time color-s2"
                >更新时间：{{ cal.time }}</van-col
              >
            </van-row>
          </van-col>
          <van-col span="4" class="btn"
            ><van-button icon="plus" block :color="COLOR_M" size="mini"
              >询价</van-button
            ></van-col
          >
        </van-row>
      </van-radio-group>
    </div>
    <van-field name="checkbox" label="结算类型">
      <template #input>
        <van-tag
          size="mini"
          :color="COLOR_M"
          v-if="calculate.purchaseType === 'PAY'"
          >付费(向提供商付费)</van-tag
        >
        <van-tag
          size="mini"
          :color="COLOR_M"
          v-if="calculate.purchaseType === 'CAG'"
          >收费(向提供商收费)</van-tag
        >
      </template>
    </van-field>
    <van-field
      v-model="calculate.priceBase"
      label="基础价格"
      placeholder="基础价格"
      @change="calExpense"
    />
    <van-field
      v-model="calculate.priceSale"
      label="销售价格"
      placeholder="销售价格"
      @change="calExpense"
    />
    <van-field
      v-model="calculate.priceOnline"
      label="线上售价"
      placeholder="线上售价"
      @change="calExpense"
    />
    <van-field
      v-model="calculate.priceOffline"
      label="线下售价"
      placeholder="线上售价"
      readonly
    />
    <van-field
      v-model="calculate.profit"
      label="销售利润"
      placeholder="销售利润"
      readonly
    />
    <van-field name="checkbox" label="同步更新价格">
      <template #input>
        <van-checkbox
          v-model="calSync"
          shape="square"
          :checked-color="COLOR_M"
        />
      </template>
    </van-field>
    <van-field name="radio" label="处理结果">
      <template #input>
        <van-radio-group
          v-model="result"
          direction="horizontal"
          @change="resultChange"
          :checked-color="COLOR_M"
        >
          <van-radio name="Y">可以办理</van-radio>
          <van-radio name="N">取消订单</van-radio>
        </van-radio-group>
      </template>
    </van-field>
    <van-field
      v-model="comment"
      is-link
      readonly
      name="picker"
      label="处理备注"
      placeholder="点击选择备注"
      @click="commentShow = true"
    />
    <van-popup :show="commentShow" position="bottom">
      <van-picker
        :columns="comments"
        @confirm="commentConfirm"
        @cancel="commentShow = false"
      />
    </van-popup>
    <van-row class="btn">
      <van-col span="8" offset="8"
        ><van-button
          icon="plus"
          block
          :color="COLOR_M"
          @click="uploadResume()"
          size="small"
          >提交审核</van-button
        ></van-col
      >
    </van-row>
  </div>
</template>
<script>
import {
  Field, RadioGroup, Radio, Checkbox, CellGroup, Cell, Tag, Popup, Picker
} from 'vant'
import Navbar from '../../common/Navbar.vue'
export default {
  components: {
    Navbar: Navbar,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Checkbox.name]: Checkbox,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Popup.name]: Popup,
    [Picker.name]: Picker
  },
  data () {
    return {
      orderCode: '',
      taskCode: '',
      calChose: '',
      calSync: true,
      calculate: { priceBase: 0, priceOnline: 0, priceOffline: 0 },
      calculateList: [],
      result: 'Y',
      comment: '初审通过',
      comments: ['初审通过'],
      commentShow: false
    }
  },
  mounted () {
    var query = this.$route.query
    this.orderCode = query.orderCode
    this.taskCode = query.taskCode
    this.retrieveTaskCalList()
    this.resultChange('Y')
  },
  methods: {
    async retrieveTaskCalList () {
      var pd = { taskCode: this.taskCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/overall/task/retrieveTaskCalculateList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.calculateList = res.data
        if (this.calculateList.length > 0) {
          this.calculate = this.calculateList[0]
          this.calChose = 0
        }
      }
    },
    resultChange (val) {
      if (val === 'Y') {
        this.comments = ['立即办理', '3天内办理', '7天内办理', '14天内办理', '30天内办理', '等待空位时间不定']
        this.comment = '立即办理'
      } else {
        this.comments = ['岗位无空缺', '办理时间无法达成一致', '价格无法达成一致', '简历不符合要求', '其他原因']
        this.comment = '岗位无空缺'
      }
    },
    changeProvider (val) {
      this.calculate = this.calculateList[val]
    },
    commentConfirm (val) {
      this.comment = val
      this.commentShow = false
    },
    applyAudit () {
      this.$dialog.confirm({
        title: '审核确认',
        message: '确认提交审核吗?'
      })
        .then(() => {
          this.applyAuditExecute()
        })
    },
    async applyAuditExecute () {
      var pd = {
        orderCode: this.orderCode,
        providerCode: this.calculate.providerCode,
        priceBase: this.calculate.priceBase,
        priceSale: this.calculate.priceSale,
        priceOnline: this.calculate.priceOnline,
        priceOffline: this.calculate.priceOffline,
        profit: this.calculate.profit,
        result: this.result,
        comment: this.comment
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/recruit/order/repeatAudit', this.$qs.stringify(pd))
      if (res.status === '200') {
        if (this.calSync) {
          this.updateCalculate()
        }
      }
    },
    async updateCalculate () {
      var pd = {
        taskCode: this.taskCode,
        oldProvider: this.calculate.providerCode,
        providerCode: this.calculate.providerCode,
        purchaseType: this.calculate.purchaseType,
        priceBase: this.calculate.priceBase,
        priceSale: this.calculate.priceSale,
        priceOnline: this.calculate.priceOnline,
        priceOffline: this.calculate.priceOffline,
        profit: this.calculate.profit
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/overall/task/updateTaskCalculate', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$router.go(-1)
      }
    },
    calExpense () {
      if (this.calculate.purchaseType === 'PAY') {
        this.calculate.profit = this.calculate.priceSale - this.calculate.priceBase
      } else {
        this.calculate.profit = this.calculate.priceSale + this.calculate.priceBase
      }
      this.calculate.priceOffline = this.calculate.priceSale - this.calculate.priceOnline
    }
  }
}
</script>
<style lang="less" scoped>
.provider {
  text-align: left;
  .title {
    line-height: 30px;
    border-bottom: 1px solid #eee;
    font-weight: 600;
    font-size: 14px;
    padding-left: 10px;
  }
  .item {
    width: 98%;
    margin-left: 1%;
    padding: 10px 0px;
    border-bottom: 1px solid #eee;
    .raido {
      margin-top: 18px;
      margin-left: 10px;
    }
    .name {
      line-height: 20px;
      font-weight: 600;
    }
    .price {
      line-height: 20px;
    }
    .time {
      line-height: 20px;
    }
    .btn {
      margin-top: 15px;
    }
  }
}
.btn {
  margin-top: 20px;
}
</style>
